import { jsx } from 'react/jsx-runtime';
import * as React from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { cn } from 'mxcn';
import Link from 'next/link';
import { buttonVariants } from './button/button';

const DropdownMenu = DropdownMenuPrimitive.Root;
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
const DropdownMenuPortal = DropdownMenuPrimitive.Portal;
const DropdownMenuContent = React.forwardRef(({ className, sideOffset = 4, ...props }, ref) => /* @__PURE__ */ jsx(DropdownMenuPrimitive.Portal, { children: /* @__PURE__ */ jsx(
  DropdownMenuPrimitive.Content,
  {
    ref,
    sideOffset,
    className: cn(
      "z-50 p-2 bg-gray-100 rounded-md shadow-md dark:shadow-primary dropdown-fade w-40 dark:bg-primary border border-secondary",
      className
    ),
    ...props
  }
) }));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;
const DropdownMenuItem = React.forwardRef(({ className, size, variant, closeOnClick = true, ...props }, ref) => {
  function handleClick(e) {
    if (!closeOnClick) {
      e.preventDefault();
      props.onClick?.(e);
    } else {
      props.onClick?.(e);
    }
  }
  return /* @__PURE__ */ jsx(
    DropdownMenuPrimitive.Item,
    {
      ref,
      className: buttonVariants({
        variant: "transparent",
        className: cn(
          "cursor-pointer my-0.5 rounded-md transition-colors w-full text-left bg-transparent hover:bg-gray-400 focus:bg-gray-400 dark:hover:bg-secondary dark:focus:bg-secondary focus-visible:outline-none",
          className,
          size,
          variant
        )
      }),
      ...props,
      onClick: handleClick
    }
  );
});
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;
const DropdownMenuLinkItem = React.forwardRef(({ className, href, ...props }, ref) => /* @__PURE__ */ jsx(DropdownMenuPrimitive.Item, { ref, ...props, asChild: true, children: /* @__PURE__ */ jsx(
  Link,
  {
    href,
    className: buttonVariants({
      className: cn(
        "outline-none block rounded-md transition-colors w-full text-left bg-transparent dark:hover:bg-secondary hover:bg-gray-400 focus:bg-gray-400 dark:focus:bg-secondary focus-visible:outline-none",
        className
      ),
      size: "sm",
      variant: "transparent"
    }),
    children: props.children
  }
) }));
DropdownMenuLinkItem.displayName = DropdownMenuPrimitive.Item.displayName;

export { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLinkItem, DropdownMenuPortal, DropdownMenuTrigger };
